<template>
  <v-container fluid v-if="question">
    <v-card class="mx-auto">
      <v-card-title>
        <span>
          {{ question.title ? question.title : "Question Text" }}
        </span>
        <template v-if="allowDelete && !readOnly">
          <v-spacer></v-spacer>
          <v-btn
            @click="deleteQuestion"
            color="error"
            :loading="isEditingSurvey"
          >
            <v-icon aria-label="Delete Question"> mdi-delete </v-icon>
            Delete Question
          </v-btn>
        </template>
      </v-card-title>
      <v-card-text>
        <question-form v-model="question" :readOnly="readOnly" />
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";
import QuestionForm from "@admin/components/surveys_new/QuestionForm.vue";

export default {
  name: "Question",
  components: {
    QuestionForm,
  },
  props: {
    id: {
      required: true,
    },
    readOnly: {
      default: false,
      required: false,
    },
  },
  computed: {
    ...mapGetters("screenerSurveys", ["isEditingSurvey"]),
    ...mapState("screenerSurveys", ["screenerSurveys", "surveyIndex"]),

    question: {
      get() {
        // Get the question to be seen. Note, technically a question can't be guaranteed to exist on the survey
        return this.screenerSurveys[this.surveyIndex].surveyData.questions[this.id];
      },
      set(value) {
        if (this.isEditingSurvey) return;
        // Set the question to be seen.
        this.alterPageQuestion({ id: this.id, data: value });
      },
    },
    // Determine if a question should be allowed to be deleted
    allowDelete() {
      // Only allow questions that aren't of a DOB survey to be deleted.
      return this.screenerSurveys[this.surveyIndex].title !== "DOB";
    },
  },
  methods: {
    ...mapActions("screenerSurveys", {
      alterPageQuestion: "alterPageQuestion",
    }),
    deleteQuestion() {
      // Don't allow for deleting a question if the survey is being edited
      if (this.isEditingSurvey) return;

      this.$emit("deleteQuestion");
    },
  },
};
</script>
