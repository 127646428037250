<template>
  <v-container fluid v-if="page">
    <v-card class="mx-auto">
      <v-card-title>
        <template v-if="!isTitleChange">
          {{ pageTitle }}
          <v-btn
              small
              color="primary"
              @click="manageTitleChange"
              class="ml-2"
              v-if="!readOnly"
          >
            <v-icon aria-label="Change Page Title"> mdi-pencil</v-icon>
            <span class="d-none normal-font" aria-hidden="true"
            >Change Page Title</span
            >
          </v-btn>
        </template>
        <template v-else>
          <validation-observer ref="pageTitleObserver">
            <validation-provider
                v-slot="{ errors }"
                name="label"
                rules="required|max:255"
            >
              <v-text-field
                  ref="pageTitle"
                  v-model="pageTitle"
                  :error-messages="errors"
                  counter
                  maxlength="255"
                  hint="The title of the Page"
                  label="Page Title"
                  aria-required="true"
                  @blur="manageTitleChange"
              ></v-text-field>
            </validation-provider>
          </validation-observer>
        </template>
        <v-btn
            v-if="canDeletePage && !readOnly"
            :loading="isEditingSurvey"
            small
            color="error"
            @click="deletePage"
            class="ml-2"
        >
          <v-icon aria-label="Delete Page"> mdi-delete</v-icon>
          <span class="d-none normal-font" aria-hidden="true">Delete Page</span>
        </v-btn>
        <template v-if="allowAddQuestion && !readOnly">
          <v-spacer></v-spacer>
          <v-btn
              color="primary"
              @click="addQuestion()"
              class="my-5"
              :loading="isEditingSurvey"
          >
            <v-icon aria-label="Add Question"> mdi-plus</v-icon>
            Add Question
          </v-btn>
        </template>
      </v-card-title>
      <v-card-text>
        <confirm-dialog ref="confirmDelete"></confirm-dialog>
        <div v-if="pageQuestionIds.length > 0">
          <question
              v-for="questionId in pageQuestionIds"
              :key="'survey-question-' + questionId"
              :id="questionId"
              class="item"
              :readOnly="readOnly"
              @deleteQuestion="deleteQuestion(questionId)"
          >
          </question>
        </div>
        <p v-else>No questions created yet for this page</p>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";
import Question from "@admin/components/surveys_new/Question.vue";
import ConfirmDialog from "@utils/components/ConfirmDialog.vue";
import {ValidationObserver, ValidationProvider} from "vee-validate";

export default {
  name: "Page",
  components: {
    Question,
    ConfirmDialog,
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    id: {
      required: true,
    },
    readOnly: {
      default: false,
      required: false,
    },
  },
  data() {
    return {
      isLoading: true,
      isSubmitting: false,
      questionIdCheck: 0,
      isTitleChange: false,
    };
  },
  computed: {
    ...mapState("screenerSurveys", ["screenerSurveys", "surveyIndex"]),
    ...mapGetters("screenerSurveys", ["isEditingSurvey", "surveyPageQuestionIds"]),
    page: {
      get() {
        // Get the page from the selected survey
        return this.screenerSurveys[this.surveyIndex].surveyData.pages[this.id];
      },
    },
    pageTitle: {
      get() {
        // Get the title of the page
        return this.page ? this.page.title : "";
      },
      set(value) {
        let updateSurvey = this.screenerSurveys;
        updateSurvey[this.surveyIndex].surveyData.pages[this.id].title = value;
        this.alterScreenerSurvey(updateSurvey);
      },
    },
    survey: {
      get() {
        // Get the survey from the selected survey
        return this.screenerSurveys[this.surveyIndex];
      },
    },
    canDeletePage() {
      // Only allow pages that aren't of a DOB survey to be deleted.
      return this.screenerSurveys[this.surveyIndex].title !== "DOB";
    },
    pageQuestionIds() {
      // Get the question ids for the page
      this.questionIdCheck;
      return this.surveyPageQuestionIds(this.id);
    },
    allowAddQuestion() {
      // Only allow questions to be added if the survey isn't being edited
      return this.screenerSurveys[this.surveyIndex].title !== "DOB";
    },
  },
  methods: {
    ...mapActions("screenerSurveys", {
      alterScreenerSurvey: "alterScreenerSurvey",
      addSurveyPageQuestion: "addSurveyPageQuestion",
      deleteSurveyPageQuestion: "deleteSurveyPageQuestion",
    }),
    deletePage() {
      // Don't allow for deleting a page if the survey is currently being edited
      if (this.isEditingSurvey) return;
      // Delete the page
      this.$emit("deleteSurveyPage", this.surveyIndex, this.id);
    },
    addQuestion() {
      if (this.isEditingSurvey) return;
      this.addSurveyPageQuestion(this.id).then(([success]) => {
        if (success) {
          this.questionIdCheck++;
          //update parent so languages can add the new question
          this.$emit("updateSurvey");
        }
      });
    },
    deleteQuestion(questionId) {
      this.$refs.confirmDelete
          .open("Confirm", "Are you sure you want to delete this question?")
          .then((confirm) => {
            if (confirm) {
              this.deleteSurveyPageQuestion({surveyIndex: this.surveyIndex, id: questionId}).then(([success]) => {
                if (success) {
                  this.questionIdCheck++;
                }
              });
            }
          });
    },
    manageTitleChange() {
      if (this.isTitleChange) {
        // When title change is true, the observer for the title field will be included
        // Check the observer on if validation has passed. If not, we don't want to transition
        // to title change not being true since we want to force the user to provide a valid title
        // before survey data can be changed
        this.$refs.pageTitleObserver.validate().then((success) => {
          if (success) {
            this.isTitleChange = false;
          }
        });
      } else {
        this.isTitleChange = true;
        this.$nextTick(() => {
          // Auto focus the title of the page when a user inititates a title change
          this.$refs.pageTitle.$refs.input.focus();
        });
      }
    },
  },
};
</script>

<style scoped></style>
