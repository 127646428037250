<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="3" class="pt-8">
        <dd v-text="engagement.text"></dd>
      </v-col>
      <v-col cols="12" md="1" class="pt-8">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on">mdi-calendar-month</v-icon>
          </template>
          <span
            >{{ engagement.mediaLiveDate }} to
            {{ engagement.mediaEndDate }}</span
          >
        </v-tooltip>
      </v-col>
      <v-col cols="12" md="3">
        <validation-provider
          v-slot="{ errors }"
          name="value"
          :rules="{
            weightRequired: engagementWeightValidation,
            max: 255,
          }"
          mode="aggressive"
        >
          <v-text-field
            :key="'weight-' + option.id"
            v-model="engagementWeight"
            label="Optional"
            class="ml-5 mt-0"
            :error-messages="errors"
            single-line
            type="number"
            hint="Please add a minimum weight of 1"
            persistent-hint
            :required="false"
            :disabled="optionDisqualifier"
          ></v-text-field>
        </validation-provider>
      </v-col>
      <v-col cols="12" md="1" offsetMd="3">
        <v-checkbox v-model="optionDisqualifier" class="mx-auto"> </v-checkbox>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import { mapActions } from "vuex";
import { extend, ValidationProvider } from "vee-validate";

extend("weightRequired", {
  validate(value) {
    if (value >= 1) {
      return true;
    }
    return false;
  },
  message: "This value must be greater than or equal to 1",
  computesRequired: true,
});

export default {
  name: "OptionEngagementWeights",
  components: {
    ValidationProvider,
  },
  props: {
    option: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
    engagement: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      dialog: false,
      isInvalid: false,
      engagementWeightValidation: false,
    };
  },
  computed: {
    ...mapFields("surveysNew", ["survey", "optionEngagements"]),
    engagementWeight: {
      get() {
        return this.option.engagementOption[this.engagement.id].weight;
      },
      set(value) {
        // set the validation for weight field
        this.engagementWeightValidation = false;
        if (value !== "") {
          this.engagementWeightValidation = true;
        }
        this.option.engagementOption[this.engagement.id].weight = value;

        let optionValue = this.option;
        this.$emit("input", optionValue);
      },
    },
    optionDisqualifier: {
      get() {
        return this.option.engagementOption[this.engagement.id].isDisqualified;
      },
      set(value) {
        this.option.engagementOption[this.engagement.id].weight = null;
        this.option.engagementOption[this.engagement.id].isDisqualified = value;

        let optionValue = this.option;
        this.$emit("input", optionValue);
      },
    },
  },
  methods: {
    ...mapActions("surveysNew", [
      "getOptionEngagements",
      "updateOptionEngagements",
    ]),
  },
  async beforeMount() {
    if (this.option.engagementOption[this.engagement.id] === undefined) {
      let tmpOptionEngagement = [];
      tmpOptionEngagement[this.engagement.id] = {
        id: null,
        weight: null,
        isDisqualified: false,
      };

      this.option.engagementOption = Object.assign(
        {},
        this.option.engagementOption,
        tmpOptionEngagement
      );
    }
  },
};
</script>

<style scoped></style>
