<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12" md="4">
          <h3>Engagement</h3>
        </v-col>
        <v-col cols="12" md="8">
          <h3>Qualified Range</h3>
        </v-col>
      </v-row>
      <v-row v-for="(engagement, index) in screenerEngagements" :key="index">
        <v-col cols="12" md="4" class="pt-8">
          {{ engagement.text }}
        </v-col>
        <v-col cols="12" md="4">
          <validation-provider
              v-slot="{ errors }"
              :name="`Minimum_${engagement.value}`"
              rules="required|numeric"
          >
            <v-text-field
                v-model="minimumValues[engagement.value]"
                :error-messages="errors"
                hint="Minimum Qualification Age of Participant"
                label="Minimum"
                @input="updateMinimumValues($event, engagement.value)"
            ></v-text-field>
          </validation-provider>
        </v-col>
        <v-col cols="12" md="4">
          <validation-provider
            v-slot="{ errors }"
            :name="`Maximum_${engagement.value}`"
            rules="required|numeric"
          >
            <v-text-field
              v-model="maximumValues[engagement.value]"
              :error-messages="errors"
              hint="Maximum Qualification Age of a Participant"
              label="Maximum"
              @input="updateMaximumValues($event, engagement.value)"
            ></v-text-field>
          </validation-provider>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { extend, ValidationProvider } from "vee-validate";
import { numeric } from "vee-validate/dist/rules";
import {mapState} from "vuex";

extend("numeric", { ...numeric, message: "Must be a positive whole number" });

export default {
  name: "BirthdateOptions",
  components: {
    ValidationProvider,
  },
  props: {
    value: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  computed: {
    ...mapState("screeners", {
      screenerEngagements: "screenerEngagements",
    }),
    minimumValues() {
        return this.screenerEngagements.reduce((values, engagement) => {
          values[engagement.value] = this.getMinimumValue(engagement.value);
          return values;
        }, {});
    },
    maximumValues() {
      return this.screenerEngagements.reduce((values, engagement) => {
        values[engagement.value] = this.getMaximumValue(engagement.value);
        return values;
      }, {});
    },
  },
  methods: {
    getMinimumValue(engageId) {
      const minOption = this.value.options.find(option => option.label === 'min' && option.engageId === engageId);
      return minOption ? minOption.value : 18;
    },
    getMaximumValue(engageId) {
      const maxOption = this.value.options.find(option => option.label === 'max' && option.engageId === engageId);
      return maxOption ? maxOption.value : 150;
    },
    updateMinimumValues(newValues, engageId) {
      const minOption = this.value.options.find(option => option.label === 'min' && option.engageId === engageId);
      if (minOption) {
        // Validation requires that this value be numeric inorder to save
        minOption.value = newValues && !isNaN(newValues) ? parseInt(newValues) : newValues;
        // Emit the updated option
        this.$emit("input", minOption);
      }
    },
    updateMaximumValues(newValues, engageId) {
      const maxOption = this.value.options.find(option => option.label === 'max' && option.engageId === engageId);
      if (maxOption) {
        // Validation requires that this value be numeric inorder to save
        maxOption.value = newValues && !isNaN(newValues) ? parseInt(newValues) : newValues;
        // Emit the updated option
        this.$emit("input", maxOption);
      }
    },
  },
};
</script>
