<template>
    <validation-provider
        v-slot="{ errors }"
        :name="'Transition to' + option.id"
        rules="required"
    >
      <v-select
          outlined
          :error-messages="errors"
          :items="transitionToList"
          item-text="text"
          item-value="value"
          label="Transition to"
          v-model="transitionTarget"
      ></v-select>
    </validation-provider>
</template>
<script>
import { ValidationProvider } from "vee-validate";
import {mapState} from "vuex";

export default {
  name: "TransitionOptions",
  components: {
    ValidationProvider,
  },
  props: {
    option: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  computed: {
    ...mapState("screenerSurveys", ["screenerSurveyTransitionList"]),
    transitionToList() {
      return this.screenerSurveyTransitionList;
    },
    transitionTarget: {
      get() {
        if (this.option.transitionTo === 'end') {
          return 'end';
        }

        return this.option.transitionTarget;
      },
      set(value) {
        if (value === 'end') {
          this.option.transitionTo = 'end';
          this.option.transitionTarget = null;
        } else {
          this.option.transitionTo = 'page';
          this.option.transitionTarget = value;
        }

        this.$emit("input", this.option);
      },
    },
  },
};
</script>
