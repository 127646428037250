<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" :md="allowTransitions ? 4 : 6">
        <validation-provider
          v-slot="{ errors }"
          :name="'Label' + value.id"
          rules="labelRequired|max:255"
        >
          <v-text-field
            outlined
            v-model="optionLabel"
            :error-messages="errors"
            counter
            maxlength="255"
            hint="The Label"
            label="Option Label"
            required
            aria-required="true"
            :disabled="readOnly"
          ></v-text-field>
        </validation-provider>
      </v-col>
      <v-col cols="12" md="3" v-if="!readOnly && allowTransitions">
        <transition-option
          :option="value"
          v-if="allowTransitions"
          @input="changeTransitionOption($event)"
        ></transition-option>
      </v-col>
      <v-col cols="12" md="4" v-if="!readOnly">
        <v-row>
          <v-col cols="12" md="4">
            <v-checkbox
                v-model="showValueField"
                label="Show Value"
            ></v-checkbox>
          </v-col>
          <v-col cols="12" md="8">
            <validation-provider
              v-if="showValueField"
              v-slot="{ errors }"
              :name="'Value' + value.id"
              rules="required|max:255"
            >
              <v-text-field
                outlined
                v-model="optionValue"
                :error-messages="errors"
                counter
                maxlength="255"
                hint="The Value"
                label="Option Value"
                required
                aria-required="true"
                :disabled="readOnly"
              ></v-text-field>
            </validation-provider>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="6"
        md="1"
        class="d-flex justify-center align-start mt-3"
        v-if="!readOnly"
      >
        <v-btn
          :icon="!isLessThanMediumBreakpoint"
          @click="deleteOption"
          color="error"
        >
          <v-icon aria-label="Delete Option"> mdi-delete </v-icon>
          <span
            class="d-inline-block d-md-none"
            :aria-hidden="!isLessThanMediumBreakpoint"
            >Delete Option</span
          >
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12" v-if="!readOnly">
        <v-row>
          <v-col cols="12" md="4" class="pl-6 pt-6 font-weight-bold">
            Engagement
          </v-col>
          <v-col cols="12" md="4" class="pl-7 pt-6 font-weight-bold">
            Weights
          </v-col>
          <v-col
            cols="12"
            md="1"
            offsetMd="2"
            class="pa-0 pl-1 font-weight-bold"
          >
            <v-checkbox
              v-model="optionDisqualifier"
              class="mx-auto"
              label="DNQ"
              :indeterminate="indeterminate"
            >
            </v-checkbox>
          </v-col>
        </v-row>
        <!--    Engagement Option component    -->
        <option-engagement-weights
          v-for="(engagement, index) in screenerEngagements"
          :option="value"
          :engagement="engagement"
          :key="index"
        ></option-engagement-weights>
      </v-col>
    </v-row>
    <v-divider :inset="true" class="mb-4"></v-divider>
  </v-container>
</template>

<script>
import { extend, ValidationProvider } from "vee-validate";
import { mapGetters, mapState } from "vuex";
import { max, required } from "vee-validate/dist/rules.js";
import OptionEngagementWeights from "@admin/components/surveys_new/OptionEngagementWeights.vue";
import TransitionOption from "@admin/components/surveys_new/TransitionOption.vue";
import camelCase from "lodash.camelcase";

extend("max", {
  ...max,
  message: "Maximum {length} characters.",
});

extend("required", {
  ...required,
  message: "Please provide a value.",
});

extend("labelRequired", {
  ...required,
  message: "Please provide an option label.",
  computesRequired: true,
});

export default {
  name: "QuestionOption",
  components: {
    TransitionOption,
    ValidationProvider,
    OptionEngagementWeights,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    // Determine if we want to allow for this option to allow for setting transitions
    allowTransitions: {
      type: Boolean,
      required: false,
      default: true,
    },
    readOnly: {
      default: false,
      required: false,
    },
  },
  data() {
    return {
      showValueField: false,
      indeterminate: null,
    };
  },
  watch: {
    surveyPagesList() {
      if (
        this.optionTransitionTarget &&
        !this.surveyPagesList.some(
          (page) => page.value === this.optionTransitionTarget
        )
      ) {
        // If the available page list changes, and the current selected transition is no longer
        // in the page list, set the transition target to null
        this.optionTransitionTarget = null;
      }
    },
  },
  computed: {
    ...mapState("surveysNew", [
      "survey",
      "surveyPages",
      "sponsorSurveys",
      "sponsorSurveyPages",
      "uniqueSponsorSurveys",
    ]),
    ...mapGetters("surveysNew", [
      "surveyPagesList",
      "surveyPage",
      "secondaryLanguageTarget",
    ]),
    ...mapGetters("systemLanguages", ["languageTargets"]),
    ...mapState("screeners", {
      screenerEngagements: "screenerEngagements",
    }),
    isLessThanMediumBreakpoint() {
      return this.$vuetify.breakpoint.sm;
    },
    optionLabel: {
      get() {
        return this.value.label;
      },
      set(value) {
        let optionValue = this.value;
        optionValue.label = value;

        if (optionValue.isNew === true) {
          optionValue.value = camelCase(value);
        }

        this.$emit("input", optionValue);
      },
    },
    optionValue: {
      get() {
        return this.value.value;
      },
      set(value) {
        let optionValue = this.value;

        if (optionValue.isNew !== true) {
          optionValue.value = value;
          this.$emit("input", optionValue);
        }
      },
    },
    optionDisqualifier: {
      get() {
        this.indeterminate = false;

        if (this.areAllDNQChecked()) {
          return true;
        } else if (this.areSomeDNQChecked()) {
          this.indeterminate = true;
          return null; // Some DNQs are checked (indeterminate)
        }

        return false; // No DNQ checked
      },
      set(value) {
        if (Object.keys(this.value.engagementOption).length > 0) {
          for (const [, engagementOption] of Object.entries(
            this.value.engagementOption
          )) {
            engagementOption.isDisqualified = value;
            if (value === true) {
              engagementOption.weight = null;
            }
          }
        }
      },
    },
    pageList: {
      get() {
        let newPagelist;
        newPagelist = this.surveyPagesList.filter((page) => {
          return page.value !== this.value.pageId;
        });
        return newPagelist;
      },
      set() {},
    },
  },
  methods: {
    deleteOption() {
      this.$emit("delete", this.value.id);
    },
    getUnfocusedLanguageLabels() {
      const languageLabels = {};

      if (this.value) {
        for (const target of this.languageTargets) {
          languageLabels[target.value + "Label"] =
            this.value[target.value + "Label"];
        }
      }

      return languageLabels;
    },
    changeTransitionOption(event) {
      this.$emit("input", event);
    },
    areAllDNQChecked() {
      if (Object.keys(this.value.engagementOption).length > 0) {
        for (const [, engagementOption] of Object.entries(
          this.value.engagementOption
        )) {
          if (!engagementOption.isDisqualified) {
            return false;
          }
        }
        return true;
      }
      return false;
    },
    areSomeDNQChecked() {
      if (Object.keys(this.value.engagementOption).length > 0) {
        for (const [, engagementOption] of Object.entries(
          this.value.engagementOption
        )) {
          if (engagementOption.isDisqualified) {
            return true;
          }
        }
      }
      return false;
    },
  },
};
</script>

<style scoped></style>
