<template>
  <div>
    <h3>Options</h3>
    <v-container v-if="!isLessThanMediumBreakpoint">
      <v-row>
        <v-col cols="12" md="4"> Labels </v-col>
        <v-col cols="12 " md="3" v-if="allowOptionTransitions"> Transition </v-col>
      </v-row>
    </v-container>
    <confirm-dialog ref="confirmDelete"></confirm-dialog>
    <question-option
      v-for="(option, index) in options"
      :key="'survey-question-option' + index"
      :value="options[index]"
      :allowTransitions="allowOptionTransitions"
      :readOnly="readOnly"
      @input="changeOption(index, $event)"
      @delete="deleteOption(option.id)"
    >
    </question-option>
    <v-container v-if="!readOnly">
      <v-row>
        <v-col cols="12">
          <v-btn color="primary" @click="addOption()" class="my-5">
            <v-icon aria-label="Add Option"> mdi-plus </v-icon>
            Add Option
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import ConfirmDialog from "@utils/components/ConfirmDialog.vue";
import QuestionOption from "@admin/components/surveys_new/QuestionOption.vue";

export default {
  name: "QuestionOptions",
  components: { QuestionOption, ConfirmDialog },
  props: {
    value: {
      type: Object,
      required: false,
      default: () => {},
    },
    questionType: {
      type: String,
      required: false,
    },
    readOnly: {
      default: false,
      required: false,
    },
  },
  data() {
    return {
      optionIdCheck: 0,
    };
  },
  computed: {
    isLessThanMediumBreakpoint() {
      return this.$vuetify.breakpoint.sm;
    },
    options: {
      get() {
        if (this.value && this.value.options) {
          return this.value.options;
        }

        return [];
      },
      set(value) {
        let questionInput = this.value;
        questionInput.options = value;
        this.$emit("input", questionInput);
      },
    },
    allowOptionTransitions() {
      // Don't allow for setting transitions for multi-select based options since
      // we don't want to deal with the complexities of how interpreting transition config
      // for this field type should work
      return this.questionType !== 'multiple';
    },
  },
  methods: {
    ...mapActions("screenerSurveys", [
        "addSurveyPageQuestionOption",
        "deleteSurveyPageQuestionOption"
    ]),
    addOption() {
      // If we're adding a new option, we need to make sure that we're passing the questionId
      let payload = this.value.id;
      // If we're adding a new option to a multiple choice question,
      // we need to make sure that we're passing the transitionTo and transitionTarget if they exist
      if (this.value.type === 'multiple' && this.value.options.length > 0) {
        payload = {
          questionId: this.value.id,
          label: null,
          value: null,
          transitionTo: this.value.options[0].transitionTo,
          transitionTarget: this.value.options[0].transitionTarget,
        };
      }
      // Don't allow for adding a question if the survey is being edited
      this.addSurveyPageQuestionOption(payload).then(([success]) => {
        if (success) {
          this.optionIdCheck++;
          //update parent so lanugages can add the new question
          //this.$emit("updateQuestion");
        }
      });
    },
    deleteOption(optionId) {
      this.$refs.confirmDelete
        .open("Confirm", "Are you sure you want to delete this option?")
        .then((confirm) => {
          if (confirm) {
            this.deleteSurveyPageQuestionOption({
              optionId: optionId,
              questionId: this.value.id,
            }).then(([success]) => {
              if (success) {
                this.optionIdCheck++;
                //this.$emit("updateQuestion");
              }
            });
          }
        });
    },
    changeOption(index, event) {
      // In order to engage reactivity, we must reassign options once we've changed the element
      // at the given index
      const mutatedOptions = this.value.options;
      mutatedOptions[index] = event;
      this.value.options = mutatedOptions;
    },
  },
};
</script>
